<template>
  <div>
    <div class="search-bar flex justify-between items-center">
      <a-form-model layout="inline" ref="searchRef">
        <a-form-model-item prop="outlets_city_adcode" label="选择城市">
          <a-select style="width:140px;" placeholder="请选择城市"
               v-model="outlets_city_adcode"
               @change="changeCity">
            <!-- <a-select-option v-if="!isCityManage" value="">全部</a-select-option> -->
            <a-select-option v-for="(city, index) in cityList"
                 :value="city.adcode"
                 :key="index">{{city.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="search_date" label="选择时间">
          <a-range-picker
            style="width:300px"
            v-model="search_date"
            valueFormat="YYYY-MM-DD"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
      <div>
        <a-button class="p-0 mr-4" type="link" @click="isShowExport = true">导出城市订单</a-button>
        <a-button class="p-0" type="link" @click="isShowExportSingle = true">导出城市单品订单</a-button>
      </div>
    </div>

    <!-- <div class="mt-2">
      <a-tabs type="card" class="w-full" @change="changeTabs" :activeKey="activeTab">
        <a-tab-pane :key="0" tab="网点交付"></a-tab-pane>
        <a-tab-pane :key="1" tab="网点下单"></a-tab-pane>
      </a-tabs>
    </div> -->

		<div class="text-sm mt-4 mb-2">
      <span class="text-lg font-bold">城市网点订单总览</span>
			<span class="ml-2">（订单应退：退款时间； 其他统计项：订单完成时间）</span>
		</div>
		<base-table
			:columnsData="columns_carry"
			:tableData="tableData_carry"
		></base-table>

		<!-- 导出 -->
		<a-modal
	    v-model="isShowExport"
	    width="500px"
	    title="导出"
	    @ok="handlerExport"
	  >
	    <a-form-model
	      ref="tempRef"
	      :labelCol="{ span: 5 }"
	      :wrapperCol="{ span: 19 }"
	    >
	      <a-form-model-item prop="outlets_city_adcode" label="城市市场">
					<a-select style="width:300px;" placeholder="请选择城市"
               v-model="exportForm1.outlets_city_adcode">
            <a-select-option v-if="!isCityManage" value="">全部</a-select-option>
            <a-select-option v-for="(city, index) in cityList"
                 :value="city.adcode"
                 :key="index">{{city.name}}</a-select-option>
          </a-select>
				</a-form-model-item>
	      <a-form-model-item prop="order_date" label="下单日期">
					<a-range-picker style="width:300px" valueFormat="YYYY-MM-DD"
            v-model="exportForm1.order_date"
          />
	      </a-form-model-item>
	      <a-form-model-item prop="audit_date" label="审核日期">
					<a-range-picker style="width:300px" valueFormat="YYYY-MM-DD"
            v-model="exportForm1.audit_date"
          />
	      </a-form-model-item>
	      <a-form-model-item prop="finish_date" label="完成日期">
					<a-range-picker style="width:300px" valueFormat="YYYY-MM-DD"
            v-model="exportForm1.finish_date"
          />
	      </a-form-model-item>
	    </a-form-model>
	  </a-modal>

	  <!-- 导出 单品订单明细 -->
		<a-modal
	    v-model="isShowExportSingle"
	    width="500px"
	    title="导出单品销售明细"
	    @ok="handlerExportSingle"
	  >
	    <a-form-model
	      ref="tempRef"
	      :labelCol="{ span: 5 }"
	      :wrapperCol="{ span: 19 }"
	    >
	      <a-form-model-item prop="outlets_city_adcode" label="城市市场">
					<a-select style="width:300px;" placeholder="请选择城市"
               v-model="exportForm2.outlets_city_adcode">
            <a-select-option v-if="!isCityManage" value="">全部</a-select-option>
            <a-select-option v-for="(city, index) in cityList"
                 :value="city.adcode"
                 :key="index">{{city.name}}</a-select-option>
          </a-select>
				</a-form-model-item>
				<a-form-model-item prop="goods_id" label="产品">
					<a-select style="width:300px;" show-search
                    placeholder="请输入产品名"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="suggestGoodsList"
                    v-model="exportForm2.goods_id">
            <a-select-option v-for="(item, index) in goodsList"
                             :key="index"
                             :value="item.goods_id">{{item.goods_name}}</a-select-option>
          </a-select>
				</a-form-model-item>
	      <a-form-model-item prop="order_date" label="下单日期">
					<a-range-picker style="width:300px" valueFormat="YYYY-MM-DD"
            v-model="exportForm2.order_date"
          />
	      </a-form-model-item>
	      <a-form-model-item prop="_dlvr_date" label="应交付日期">
					<a-range-picker style="width:300px" valueFormat="YYYY-MM-DD"
            v-model="exportForm2.dlvr_date"
          />
	      </a-form-model-item>
	    </a-form-model>
	  </a-modal>
  </div>
</template>

<script>
import pageData from "./columns"
import { 
	financeCityCarry
} from "@/api/finance/finance.js"
import { 
	getGoodsList
} from "@/api/goods.js"
import { 
	exportOrderGoods
} from "@/api/finance/analysis.js"
import { 
	exportGoodSaledList
} from "@/api/regional/analysis.js"
import { 
  getMarketCityList,
  isCityManage,
} from '@/utils/authData.js'

import { formatEnMoney } from "@/utils/index.js"

export default {
  data() {
    return {
			activeTab: 0,
			search_date: [],
			outlets_city_adcode: undefined,
			cityList: [],
      isCityManage: isCityManage(),

			...pageData,
			tableData_carry: [],
			columns_carry: [
				{
					title: "网点名称",
					dataIndex: "outlets_name",
					align: "center",
				},
				{
					title: "类型",
					dataIndex: "outlets_type",
					align: "center",
					slots: {
						customRender: "outlets_type",
					},
					slotsType: "format",
					slotsFunc: (val) => {return val==1?"门店":(val==2?"配送站":"")},
				},
				{
					title: "自提订单数",
					dataIndex: "pick_up_count",
					align: "center",
					width: "100px",
				},
				{
					title: "配送订单数",
					dataIndex: "delivery_count",
					align: "center",
					width: "100px",
				},
				{
					title: "订单应收总额(元)",
					dataIndex: "total_receivable_amount",
					align: "center",
					slots: {
						customRender: "total_receivable_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "货到付款金额(元)",
					dataIndex: "cash_delivery_income_amount",
					align: "center",
					slots: {
						customRender: "cash_delivery_income_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "订单应退(元)",
					dataIndex: "bear_refund_amount",
					align: "center",
					slots: {
						customRender: "bear_refund_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			isShowExport: false,
			exportForm1: {
				outlets_city_adcode: undefined,
				order_date: [],
				audit_date: [],
				finish_date: [],
			},

			isShowExportSingle: false,
			exportForm2: {
				outlets_city_adcode: undefined,
				order_date: [],
				dlvr_date: [],
			},
			goodsList: [],

    }
  },
  async mounted() {
  	this.cityList = await getMarketCityList()
  	if(this.isCityManage){
      if(this.cityList.length == 1){
      	this.outlets_city_adcode = this.cityList[0].adcode
				this.exportForm1.outlets_city_adcode = this.cityList[0].adcode
				this.exportForm2.outlets_city_adcode = this.cityList[0].adcode
      }
    }else{
    	// this.outlets_city_adcode = ""
			this.exportForm1.outlets_city_adcode = ""
			this.exportForm2.outlets_city_adcode = ""
    }

		this.search_date = [
			this.getThisMonthFirstDay(),
			this.getToday(),
		]
		
		if(this.outlets_city_adcode){
			this.handlerSearch()
		}
  },
  methods: {
    // changeTabs(){
    //     this.activeTab = this.activeTab==1 ? 0 : 1
    // },
    changeCity(){
			this.handlerSearch()
    },
    async initDataCarryOrder() {
			const params = {
				outlets_city_adcode: this.outlets_city_adcode,
				time_start: this.search_date[0] + ' 00:00:00',
				time_end:   this.search_date[1] + ' 23:59:59'
			}
			const { data, code } = await financeCityCarry(params)
			if (code === 0) {
				let totalItem = {
					outlets_name: "合计",
					pick_up_count: 0,
					delivery_count: 0,
					total_receivable_amount: 0,
					bear_refund_amount: 0,
					cash_delivery_income_amount: 0,
				}
				data.list.map(el=>{
					totalItem.pick_up_count += el.pick_up_count
					totalItem.delivery_count += el.delivery_count
					totalItem.total_receivable_amount += Number(el.total_receivable_amount)
					totalItem.bear_refund_amount += Number(el.bear_refund_amount)
					totalItem.cash_delivery_income_amount += Number(el.cash_delivery_income_amount)
				})
				totalItem.total_receivable_amount = totalItem.total_receivable_amount.toFixed(2)
				totalItem.bear_refund_amount = totalItem.bear_refund_amount.toFixed(2)
				totalItem.cash_delivery_income_amount = totalItem.cash_delivery_income_amount.toFixed(2)
				
				this.tableData_carry = data.list.concat(totalItem)
			}
    },

   //  async initDataPlaceOrder() {
			// const params = {
			// 	outlets_city_adcode: this.outlets_city_adcode,
			// 	time_start: this.search_date[0] + '00:00:00',
			// 	time_end:   this.search_date[1] + '23:59:59'
			// }
			// const { data, code } = await financeOutletsPlace(params)
			// if (code === 0) {
			// 	this.tableData_place = [data]
			// 	this.tableData_placepay = [data.order_amount_data]
			// }
   //  },

   //  async initDataCourier() {
			// const params = {
			// 	outlets_city_adcode: this.outlets_city_adcode,
			// 	time_start: this.search_date[0] + '00:00:00',
			// 	time_end:   this.search_date[1] + '23:59:59'
			// }
			// const { data, code } = await financeOutletsCourier(params)
			// if (code === 0) {
			// 	let columns_courier = []
			// 	let data_courier = {}
			// 	data.list.map(item=>{
			// 		let dataIndex = "id_"+columns_courier.length
			// 		columns_courier.push({
			// 			title: item.name,
			// 			dataIndex: dataIndex,
			// 			align: "center",
			// 		})
			// 		data_courier[dataIndex] = item.order_count+" 单"
			// 	})

			// 	this.columns_courier = columns_courier
			// 	this.tableData_courier = [data_courier]
			// }
   //  },

    // 搜索
    handlerSearch() {
    	if(!this.outlets_city_adcode){
				this.$message.warning("请选择城市")
				return
			}
			if (this.search_date.length < 2) {
				this.$message.warning("请选择日期")
				return
			}
			this.initDataCarryOrder()
			// this.initDataCourier()

			// this.initDataPlaceOrder()
    },

    // 导出订单列表
    handlerExport(){
    	if(this.isCityManage && !this.exportForm1.outlets_city_adcode){
				this.$message.warning("请选择城市")
				return
			}
			if (this.exportForm1.order_date.length < 2 && this.exportForm1.audit_date.length < 2 && this.exportForm1.finish_date.length < 2) {
				this.$message.warning("下单、审核、完成时间至少填写一项")
				return
			}

			let params = {
				outlets_city_adcode: this.exportForm1.outlets_city_adcode
			}
			if(this.exportForm1.order_date.length == 2){
				if(new Date(this.exportForm1.order_date[1]).getTime() - new Date(this.exportForm1.order_date[0]).getTime() > 31 * 24 * 3600 * 1000){
					this.$message.warning("下单时间跨度不能超1个月")
					return
				}
				Object.assign(params, {
					time_start: this.exportForm1.order_date[0] + " 00:00:00",
					time_end:   this.exportForm1.order_date[1] + " 23:59:59",
				})
			}
			if(this.exportForm1.audit_date.length == 2){
				if(new Date(this.exportForm1.audit_date[1]).getTime() - new Date(this.exportForm1.audit_date[0]).getTime() > 31 * 24 * 3600 * 1000){
					this.$message.warning("审核时间跨度不能超1个月")
					return
				}
				Object.assign(params, {
					audit_time_start: this.exportForm1.audit_date[0] + " 00:00:00",
					audit_time_end:   this.exportForm1.audit_date[1] + " 23:59:59",
				})
			}
			if(this.exportForm1.finish_date.length == 2){
				if(new Date(this.exportForm1.finish_date[1]).getTime() - new Date(this.exportForm1.finish_date[0]).getTime() > 31 * 24 * 3600 * 1000){
					this.$message.warning("完成时间跨度不能超1个月")
					return
				}
				Object.assign(params, {
					finish_time_start: this.exportForm1.finish_date[0] + " 00:00:00",
					finish_time_end:   this.exportForm1.finish_date[1] + " 23:59:59",
				})
			}

			// 新窗口打开页面
			exportOrderGoods(params)
    },

    // 导出单个产品的订单明细
    handlerExportSingle(){
    	if(this.isCityManage && !this.exportForm2.outlets_city_adcode){
				this.$message.warning("请选择城市")
				return
			}
			if(this.isCityManage && !this.exportForm2.outlets_city_adcode){
				this.$message.warning("请选择城市")
				return
			}
			if (!this.exportForm2.goods_id) {
				this.$message.warning("请选择商品")
				return
			}

			let params = {
				outlets_city_adcode: this.exportForm2.outlets_city_adcode,
				goods_id: this.exportForm2.goods_id,
			}
			if(this.exportForm2.order_date.length == 2){
				if(new Date(this.exportForm2.order_date[1]).getTime() - new Date(this.exportForm2.order_date[0]).getTime() > 31 * 24 * 3600 * 1000){
					this.$message.warning("下单时间跨度不能超1个月")
					return
				}
				Object.assign(params, {
					add_time_start: this.exportForm2.order_date[0] + " 00:00:00",
					add_time_end:    this.exportForm2.order_date[1] + " 23:59:59",
				})
			}
			if(this.exportForm2.dlvr_date.length == 2){
				if(new Date(this.exportForm2.dlvr_date[1]).getTime() - new Date(this.exportForm2.dlvr_date[0]).getTime() > 31 * 24 * 3600 * 1000){
					this.$message.warning("待交付时间跨度不能超1个月")
					return
				}
				Object.assign(params, {
					delivery_time_start: this.exportForm2.dlvr_date[0] + " 00:00:00",
					delivery_time_end:   this.exportForm2.dlvr_date[1] + " 23:59:59",
				})
			}

			// 新窗口打开页面
			exportGoodSaledList(params)
    },

    async suggestGoodsList(value){
    	if(!value){
    		this.goodsList = []
    		return
    	}
    	const params = {
        goods_name: value,
        page_count: 10,
      }
      const { data, code } = await getGoodsList(params)
      if (code == 0) {
        this.goodsList = data.list
      }
    },

    // -----------
    getLastMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			// 1月：上一个月 是去年12月
			if(month == 0){
				month = 11
				year = year-1
			}else{
				month = month-1
			}
			return this.formatDate(new Date(year, month, 1))
    },
    getLastMonthLastDay(){
			let date = new Date()
			date.setTime(date.getTime() - date.getDate()*24*3600*1000)

			return this.formatDate(date)
    },
    getThisMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			return this.formatDate(new Date(year, month, 1))
    },
    getToday(){
			let date = new Date()
			return this.formatDate(date)
    },

    formatDate(date){
			let year = date.getFullYear()
			let month = date.getMonth()
			let day = date.getDate()
			month = month+1
			if(month < 10){
				month = "0"+month
			}
			if(day < 10){
				day = "0"+day
			}
			return year+"-"+month+"-"+day
    },

  },
}
</script>

<style>
</style>